.Footer-container {
  height: 10vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.Footer-content {
  width: 100%;
  height: 10vh;
  background-color: #1b2129;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Roboto";
  font-weight: 300;
  font-size: calc(4px + 1vh);
}

.Footer-legal {
  display: flex;
  flex-direction: column;
  padding-right: calc(3px + 1vw);
  padding-left: calc(3px + 1vw);
}

.Brand {
  font-size: calc(4px + 1.3vh);
  font-weight: 500;
}

.Footer-legal-bis {
  display: flex;
  flex-direction: row;
  margin: auto;
  flex-flow: wrap;
  padding-right: calc(3px + 1vw);
  padding-left: calc(3px + 1vw);
}

.Footer-legal-bis > p {
  padding-right: 0.5vh;
  padding-left: 0.5vh;
  margin: auto;
}

.Social-logo {
  width: 3vh;
}

.Up-arrow {
  width: 3vh;
}

.Separator {
  border-right: 1px solid white;
  height: 0.8em;
  margin: auto;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: #ff7e00;
}
