.Dots-container {
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: calc(3.75% - 5px);
  z-index: 3;
}

.Nav-dot {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 10px;
}

.Nav-dot-active {
  background-color: white;
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.Dot {
  display: flex;
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-radius: 20px;
}
