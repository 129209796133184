.Games-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #61c0ed;
  height: 100vh;
}

.Games-content {
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Games-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
  align-self: flex-start;
}

.Games-offset {
  height: calc(100vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
  flex-direction: column;
}

.Games-Icons-Container {
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 5vh;
  width: 100vw;
}

.Games-Informations {
  background-color: white;
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  flex-direction: column;
  height: 55vh;
  width: 100vw;
  box-shadow: -10px white;
}

.Games-Icons-List {
  display: flex;
  list-style-type: none;
  justify-items: center;
  align-items: center;
  padding: 1vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.Games-Icon {
  padding: 1vmin;
}

.Games-Icon-Image {
  width: 10vh;
}

.Games-Icon-Image:hover {
  transition: margin-bottom 0.5s ease, filter 0.5s ease;
  filter: brightness(105%);
  margin-bottom: 2vh;
}

.Game-media {
  overflow: hidden;
  width: 100%;
}

.Game-name-container {
  padding-top: 1vh;
  padding-bottom: 1vh;
  align-self: flex-start;
  width: inherit;
  background-color: white;
}

.Game-description-container {
  align-self: flex-end;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding-bottom: 2vh;
}

.Game-name {
  padding: 0;
  margin: 0;
  color: #1b2129;
}

.Spacer {
  content: " ";
  height: 75%;
}

.Game-description {
  padding-top: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  margin: 0;
  font-size: 0.8em;
  line-height: 1.5em;
  font-weight: 500;
  color: #1b2129;
}

.Game-badge-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
}

.Game-badge-picture {
  margin: auto;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: 5vh;
}
