.App {
  text-align: center;
  font-style: normal;
}

.App-content {
  background-color: #dce3ed;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.Header-container {
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #1b2129;
  z-index: 2;
}

.Navigation-container {
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 3;
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif, "Roboto";
  font-size: calc(4px + 2vmin);
}

h1 {
  font-family: sans-serif, "Roboto";
  font-weight: 500;
  font-stretch: ultra-expanded;
  font-size: calc(10px + 4vmin);
  color: #1b2129;
}

h2 {
  font-family: sans-serif, "Roboto";
  font-weight: 400;
  font-size: calc(8px + 2vmin);
  color: white;
}
