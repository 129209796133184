.App-header {
  width: 85%;
  min-height: 50px;
  display: flex;
  color: #00476b;
  padding-bottom: 6px;
  padding-top: 6px;
  margin: auto;
  z-index: 1;
}

.App-link {
  height: inherit;
  color: white;
  vertical-align: middle;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  text-decoration: solid;
  margin: auto;
}

.App-link-text {
  color: inherit;
  font: inherit;
  text-decoration: inherit;
}

.App-link-active {
  height: inherit;
  color: #ff7e00;
  vertical-align: middle;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
  text-decoration: solid;
  margin: auto;
}

.App-link-text:hover {
  color: #ff7e00;
  font: inherit;
  text-decoration: inherit;
}

.Link-separator {
  padding-left: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 20px;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .App-logo {
    width: 100px;
  }

  .App-link {
    height: inherit;
    color: #003853;
    vertical-align: middle;
    font-family: "Roboto";
    font-weight: 500;
    font-size: calc(8px + 1vw);
    text-decoration: solid;
    margin: auto;
    padding-left: 0px;
  }

  .App-link-active {
    height: inherit;
    color: #ff7e00;
    vertical-align: middle;
    font-family: "Roboto";
    font-weight: 800;
    font-size: calc(8px + 1vw);
    text-decoration: solid;
    margin: auto;
    padding-left: 0px;
  }

  .Link-container {
    display: none;
    position: fixed;
    top: 62px;
    right: 0px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  .Link-separator {
    padding-left: 0px;
    border-right: 1px solid #ff7e00;
    height: 20px;
    margin: 0;
  }

  .App-menu {
    display: block;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    box-sizing: border-box;
  }

  .App-menu-icon {
    width: 30px;
  }

  .App-menu-icon-active {
    width: 30px;
    transform: rotate(90deg);
  }
}

@media only screen and (min-width: 768px) {
  .App-logo {
    width: 130px;
  }

  .App-menu {
    display: none;
  }

  .Link-container {
    display: flex !important;
    margin-left: auto;
  }
}

.Logo-container {
  display: block;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.is-current {
  font-weight: 800;
}
