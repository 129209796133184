.Contact-container {
  width: 100%;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #dce3ed;
}

.Contact-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.Contact-offset {
  height: calc(90vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
}

.Contact-maintext {
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-top: calc(3vh + 2vw);
  padding-bottom: calc(3vh + 2vw);
  height: 30vh;
  width: 100vw;
  background-color: white;
}

.Contact-maintext > div {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.Contact-maintext > div > a {
  text-decoration: solid;
  font-weight: 600;
  font-size: 1.25em;
  color: #61c0ed;
}

.Contact-maintext > div > a:hover {
  color: #ff7e00;
}

.Contact-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
}

.Contact-social-media {
  display: flex;
  list-style-type: none;
  justify-items: center;
  align-items: center;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2vmin;
  padding-bottom: 0;
}

.Contact-social-media > li {
  padding-right: 2vmin;
  padding-left: 2vmin;
}

.Contact-social-logo {
  width: 5vh;
}

.Contact-social-logo:hover {
  transition: filter 0.5s ease;
  filter: hue-rotate(160deg) brightness(400%) saturate(300%);
}
