.About-container {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #ffc657;
  align-content: center;
}

.About-content {
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.About-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
  align-self: flex-start;
}

.About-offset {
  height: calc(100vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
}

.About-text {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: inherit;
  padding-top: 1vh;
}

.Text-bubble {
  background-color: white;
  padding-top: calc(3vh + 2vw);
  padding-bottom: calc(3vh + 2vw);
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 35vh;
  width: 100vw;
}

.Text-bubble > * {
  width: 75%;
  margin: auto;
}

.TitleandPortrait {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: -3vh;
  position: relative;
  top: 3vh;
  height: inherit;
}

.Portrait {
  image-rendering: smooth;
  height: 10vh;
  align-self: flex-end;
  z-index: 0;
}

.About-text::before {
  content: "";
  position: relative;
  top: 0;
  width: 0;
  height: 0;
  border: 2vh solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  border-left: 0;
}

.Text-bubble > div > b > a {
  text-decoration: solid;
  color: #61c0ed;
}
