@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-style: normal;
}

.App-content {
  background-color: #dce3ed;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.Header-container {
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #1b2129;
  z-index: 2;
}

.Navigation-container {
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 3;
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif, "Roboto";
  font-size: calc(4px + 2vmin);
}

h1 {
  font-family: sans-serif, "Roboto";
  font-weight: 500;
  font-stretch: ultra-expanded;
  font-size: calc(10px + 4vmin);
  color: #1b2129;
}

h2 {
  font-family: sans-serif, "Roboto";
  font-weight: 400;
  font-size: calc(8px + 2vmin);
  color: white;
}

.Dots-container {
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: calc(3.75% - 5px);
  z-index: 3;
}

.Nav-dot {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 10px;
}

.Nav-dot-active {
  background-color: white;
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.Dot {
  display: flex;
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-radius: 20px;
}

.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 1 !important;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Featured-container {
  height: calc(100vh - 62px);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 62px;
}

.Featured-content {
  width: 100%;
  height: calc(100vh - 62px);
  background-color: white;
}

.slide-container {
  z-index: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 62px);
  width: 100%;
}

.each-slide {
  width: 100%;
}

.each-slide span {
  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 0.8em !important;
  color: white;
  text-align: center;
  position: relative;
  bottom: 62px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicators {
  display: flex;
  position: relative;
  bottom: 62px;
  left: 0;
  right: 0;
  margin: auto;
}

.indicators > div {
  min-width: 10px;
  min-height: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div.active {
  background: rgba(255, 255, 255, 1) !important;
}

.react-slideshow-container .nav:first-of-type {
  content: "▶";
  margin-right: -5vh !important;
  position: relative;
  align-self: flex-end;
  left: 30vw;
  bottom: 31px;
}

.react-slideshow-container .nav:last-of-type {
  content: "▶";
  margin-left: -5vh !important;
  position: relative;
  align-self: flex-end;
  right: 30vw;
  bottom: 31px;
}

.react-slideshow-container .nav {
  height: 2vh !important;
  background: none !important;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-slideshow-container .nav span {
  margin: auto !important;
  display: block;
  width: 2vh !important;
  height: 2vh !important;
  border-color: white !important;
  border-style: solid;
  border-width: 1vh !important;
  transform: rotate(45deg) !important;
}

.react-slideshow-wrapper.slide {
  width: auto !important;
  overflow: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .Featured-container {
    height: calc(100vh - 62px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: 62px;
  }
}

.Games-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #61c0ed;
  height: 100vh;
}

.Games-content {
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.Games-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
  align-self: flex-start;
}

.Games-offset {
  height: calc(100vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
  flex-direction: column;
}

.Games-Icons-Container {
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 5vh;
  width: 100vw;
}

.Games-Informations {
  background-color: white;
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  flex-direction: column;
  height: 55vh;
  width: 100vw;
  box-shadow: -10px white;
}

.Games-Icons-List {
  display: flex;
  list-style-type: none;
  justify-items: center;
  align-items: center;
  padding: 1vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.Games-Icon {
  padding: 1vmin;
}

.Games-Icon-Image {
  width: 10vh;
}

.Games-Icon-Image:hover {
  transition: margin-bottom 0.5s ease, -webkit-filter 0.5s ease;
  transition: margin-bottom 0.5s ease, filter 0.5s ease;
  transition: margin-bottom 0.5s ease, filter 0.5s ease, -webkit-filter 0.5s ease;
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
  margin-bottom: 2vh;
}

.Game-media {
  overflow: hidden;
  width: 100%;
}

.Game-name-container {
  padding-top: 1vh;
  padding-bottom: 1vh;
  align-self: flex-start;
  width: inherit;
  background-color: white;
}

.Game-description-container {
  align-self: flex-end;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding-bottom: 2vh;
}

.Game-name {
  padding: 0;
  margin: 0;
  color: #1b2129;
}

.Spacer {
  content: " ";
  height: 75%;
}

.Game-description {
  padding-top: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  margin: 0;
  font-size: 0.8em;
  line-height: 1.5em;
  font-weight: 500;
  color: #1b2129;
}

.Game-badge-container {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
}

.Game-badge-picture {
  margin: auto;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: 5vh;
}

.About-container {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #ffc657;
  align-content: center;
}

.About-content {
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.About-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
  align-self: flex-start;
}

.About-offset {
  height: calc(100vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
}

.About-text {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: inherit;
  padding-top: 1vh;
}

.Text-bubble {
  background-color: white;
  padding-top: calc(3vh + 2vw);
  padding-bottom: calc(3vh + 2vw);
  line-height: 1.5em;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 35vh;
  width: 100vw;
}

.Text-bubble > * {
  width: 75%;
  margin: auto;
}

.TitleandPortrait {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: -3vh;
  position: relative;
  top: 3vh;
  height: inherit;
}

.Portrait {
  image-rendering: smooth;
  height: 10vh;
  align-self: flex-end;
  z-index: 0;
}

.About-text::before {
  content: "";
  position: relative;
  top: 0;
  width: 0;
  height: 0;
  border: 2vh solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  border-left: 0;
}

.Text-bubble > div > b > a {
  -webkit-text-decoration: solid;
          text-decoration: solid;
  color: #61c0ed;
}

.Contact-container {
  width: 100%;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-color: #dce3ed;
}

.Contact-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.Contact-offset {
  height: calc(90vh - 62px);
  display: flex;
  margin-top: 62px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  align-items: center;
}

.Contact-maintext {
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-top: calc(3vh + 2vw);
  padding-bottom: calc(3vh + 2vw);
  height: 30vh;
  width: 100vw;
  background-color: white;
}

.Contact-maintext > div {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.Contact-maintext > div > a {
  -webkit-text-decoration: solid;
          text-decoration: solid;
  font-weight: 600;
  font-size: 1.25em;
  color: #61c0ed;
}

.Contact-maintext > div > a:hover {
  color: #ff7e00;
}

.Contact-title {
  margin: auto;
  padding-bottom: 1vh;
  display: flex;
}

.Contact-social-media {
  display: flex;
  list-style-type: none;
  justify-items: center;
  align-items: center;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2vmin;
  padding-bottom: 0;
}

.Contact-social-media > li {
  padding-right: 2vmin;
  padding-left: 2vmin;
}

.Contact-social-logo {
  width: 5vh;
}

.Contact-social-logo:hover {
  transition: -webkit-filter 0.5s ease;
  transition: filter 0.5s ease;
  transition: filter 0.5s ease, -webkit-filter 0.5s ease;
  -webkit-filter: hue-rotate(160deg) brightness(400%) saturate(300%);
          filter: hue-rotate(160deg) brightness(400%) saturate(300%);
}

.App-header {
  width: 85%;
  min-height: 50px;
  display: flex;
  color: #00476b;
  padding-bottom: 6px;
  padding-top: 6px;
  margin: auto;
  z-index: 1;
}

.App-link {
  height: inherit;
  color: white;
  vertical-align: middle;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  padding-left: 20px;
  -webkit-text-decoration: solid;
          text-decoration: solid;
  margin: auto;
}

.App-link-text {
  color: inherit;
  font: inherit;
  text-decoration: inherit;
}

.App-link-active {
  height: inherit;
  color: #ff7e00;
  vertical-align: middle;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
  -webkit-text-decoration: solid;
          text-decoration: solid;
  margin: auto;
}

.App-link-text:hover {
  color: #ff7e00;
  font: inherit;
  text-decoration: inherit;
}

.Link-separator {
  padding-left: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  height: 20px;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .App-logo {
    width: 100px;
  }

  .App-link {
    height: inherit;
    color: #003853;
    vertical-align: middle;
    font-family: "Roboto";
    font-weight: 500;
    font-size: calc(8px + 1vw);
    -webkit-text-decoration: solid;
            text-decoration: solid;
    margin: auto;
    padding-left: 0px;
  }

  .App-link-active {
    height: inherit;
    color: #ff7e00;
    vertical-align: middle;
    font-family: "Roboto";
    font-weight: 800;
    font-size: calc(8px + 1vw);
    -webkit-text-decoration: solid;
            text-decoration: solid;
    margin: auto;
    padding-left: 0px;
  }

  .Link-container {
    display: none;
    position: fixed;
    top: 62px;
    right: 0px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  .Link-separator {
    padding-left: 0px;
    border-right: 1px solid #ff7e00;
    height: 20px;
    margin: 0;
  }

  .App-menu {
    display: block;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    box-sizing: border-box;
  }

  .App-menu-icon {
    width: 30px;
  }

  .App-menu-icon-active {
    width: 30px;
    transform: rotate(90deg);
  }
}

@media only screen and (min-width: 768px) {
  .App-logo {
    width: 130px;
  }

  .App-menu {
    display: none;
  }

  .Link-container {
    display: flex !important;
    margin-left: auto;
  }
}

.Logo-container {
  display: block;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.is-current {
  font-weight: 800;
}

.Footer-container {
  height: 10vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.Footer-content {
  width: 100%;
  height: 10vh;
  background-color: #1b2129;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Roboto";
  font-weight: 300;
  font-size: calc(4px + 1vh);
}

.Footer-legal {
  display: flex;
  flex-direction: column;
  padding-right: calc(3px + 1vw);
  padding-left: calc(3px + 1vw);
}

.Brand {
  font-size: calc(4px + 1.3vh);
  font-weight: 500;
}

.Footer-legal-bis {
  display: flex;
  flex-direction: row;
  margin: auto;
  flex-flow: wrap;
  padding-right: calc(3px + 1vw);
  padding-left: calc(3px + 1vw);
}

.Footer-legal-bis > p {
  padding-right: 0.5vh;
  padding-left: 0.5vh;
  margin: auto;
}

.Social-logo {
  width: 3vh;
}

.Up-arrow {
  width: 3vh;
}

.Separator {
  border-right: 1px solid white;
  height: 0.8em;
  margin: auto;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: #ff7e00;
}

