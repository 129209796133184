.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 1 !important;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Featured-container {
  height: calc(100vh - 62px);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 62px;
}

.Featured-content {
  width: 100%;
  height: calc(100vh - 62px);
  background-color: white;
}

.slide-container {
  z-index: 0;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 62px);
  width: 100%;
}

.each-slide {
  width: 100%;
}

.each-slide span {
  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 0.8em !important;
  color: white;
  text-align: center;
  position: relative;
  bottom: 62px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.indicators {
  display: flex;
  position: relative;
  bottom: 62px;
  left: 0;
  right: 0;
  margin: auto;
}

.indicators > div {
  min-width: 10px;
  min-height: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div.active {
  background: rgba(255, 255, 255, 1) !important;
}

.react-slideshow-container .nav:first-of-type {
  content: "▶";
  margin-right: -5vh !important;
  position: relative;
  align-self: flex-end;
  left: 30vw;
  bottom: 31px;
}

.react-slideshow-container .nav:last-of-type {
  content: "▶";
  margin-left: -5vh !important;
  position: relative;
  align-self: flex-end;
  right: 30vw;
  bottom: 31px;
}

.react-slideshow-container .nav {
  height: 2vh !important;
  background: none !important;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-slideshow-container .nav span {
  margin: auto !important;
  display: block;
  width: 2vh !important;
  height: 2vh !important;
  border-color: white !important;
  border-style: solid;
  border-width: 1vh !important;
  transform: rotate(45deg) !important;
}

.react-slideshow-wrapper.slide {
  width: auto !important;
  overflow: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .Featured-container {
    height: calc(100vh - 62px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: 62px;
  }
}
